<template>
    <div>
        <table-component
            :header="header"
            :data="data"
            :total="total"
            :cta="cta"
            :state="state"
            :id="'members'"
			:searchTerm="searchTerm"
			:isFetching="isFetching"
			:isInitializing="isInitializing"
			@setSearchTerm="setSearchTerm"
			@setSort="setSort"
			@setPerPage="setPerPage"
        >
			<template v-slot:filters>
				<members-table-filters-component
					:filters="filters"
					@setFilters="setFilters"
					@resetFilters="resetFilters"
				>
				</members-table-filters-component>
			</template>
        </table-component>
    </div>
</template>

<script>
	import { request } from '@/config';
	import { table } from '@/mixins';
	import { mapActions } from 'vuex';

	export default {
		name: 'MembersTable',
		mixins: [table],
		data() {
			return {
				header: [
					{
						label: this.$t('company-name'),
						type: 'text',
						noWrap: true,
						sort: true,
						key: 'companyTitle',
					},
					{ label: this.$t('email'), type: 'email', sort: true, key: 'email' },
					{ label: '#' + this.$t('resources'), type: 'number', default: 0, sort: true },
					{ label: this.$t('publicResources'), type: 'number', default: 0, sort: true },
					{
						label: this.$t('created-date'),
						type: 'date',
						sort: true,
						key: 'createdAt',
					},
					{ label: this.$t('category'), type: 'text', sort: true },
					{ label: this.$t('subscription-type'), type: 'text' },
					{ label: this.$t('partner'), type: 'text' },
					{ label: this.$t('active'), type: 'boolean' },
					{
						label: this.$t('contact-person'),
						type: 'text',
						sort: true,
						key: 'contactPerson',
					},
					{
						label: this.$t('address'),
						type: 'address',
						sort: true,
						key: 'streetAddress',
					},
					{ label: this.$t('city'), type: 'text', sort: true, key: 'city' },
					{
						label: this.$t('phone-number'),
						type: 'text',
						sort: true,
						key: 'phoneNumber',
					},
					{ label: 'T&C', type: 'boolean' },
					{ label: this.$t('company-id'), type: 'id' },
					{ label: this.$t('user-id'), type: 'id' },
					{ label: "locale", type: 'text' },
				],
				data: {
					table: [],
					metaData: [],
				},
				total: 0,
				cta: [
					{
						label: () => this.$t('edit'),
						onClick: (member) => {
							console.log(member);
							const memberId = member[14];
							this.editMember(memberId);
						},
					},
					{
						label: (member) => {
							const isActive = member[7];
							return isActive ? this.$t('disable') : this.$t('enable');
						},
						onClick: (member) => {
							const memberId = member[13];
							const disabled = member[7];
							this.setMemberStatus(memberId, !disabled);
						},
						style: (member) => {
							const isActive = member[7];
							return isActive ? 'danger' : 'success';
						},
					},
				],
				filters: {
					category: '',
					status: '',
				},
			};
		},
		mounted() {
			this.getData();
		},
		watch: {
			state: {
				handler: function () {
					this.getData();
				},
				deep: true,
			},
			searchTerm: function () {
				this.getData();
			},
			filters: function () {
				this.getData();
			},
		},
		methods: {
			...mapActions({
				attemptUpdateMemberStatus:
					'users/attemptUpdateMemberStatus',
			}),
			setData(data) {
				this.data = data;
			},
			getOperatorFullName(item) {
				if (item.contactPerson) {
					return `${item.contactPerson.firstName} ${item.contactPerson.lastName}`;
				}
				return '';
			},
			getData: async function () {
				this.isFetching = true;
				try {
					const data = {
						table: [],
						metaData: [],
					};

					const requestParams = {
						locale: this.locale,
						isDatatable: true,
						page: this.state.page,
						load: this.state.perPage,
						q: {
							filter: {},
							search: {
								columns: [
									'$locale.title$',
									'email',
									'$category.category.categoryLocale.title$',
									'$contactPerson.firstName$',
									'$contactPerson.lastName$',
									'streetAddress',
									'city',
									'id',
								],
								searchTerm: this.searchTerm,
							},
						},
					};

					if (this.filters.category) {
						requestParams.q.filter['$category.category.id$'] =
							this.filters.category;
					}
						if (this.filters.status) {
							requestParams.q.filter['disabled'] =
								this.filters.status === 'active' ? '0' : '1';
						}

					if (
						this.state.sort.index != null &&
						this.state.sort.direction != null
					) {
						requestParams.orderBy =
							this.header[this.state.sort.index].key;
						requestParams.orderDirection =
							this.state.sort.direction.toUpperCase();
					}
					const res = await request
						.get('users/members', {
							headers: {
								Authorization: `Bearer ${this.token}`,
							},
							params: requestParams,
						})
						.then(({ data: { data: response } }) => response);

					const { data: members, total } = res

					this.validateResponse(res)
					data.table.push(...this.formatData(members))

					console.log(data.table)
					this.data = data;
					this.total = total;
					this.isFetching = false;
					this.isInitializing = false;
					this.scrollTableToTop()
				} catch (err) {
					console.log({ err });
				}
			},
			formatData(data){
				const formattedData = []
				data.forEach(item => {
					formattedData.push([
						item.locale && item.locale.length > 0
							? item.locale[0].title
							: '',
						item.email,
						item.resources,
						item.publicResource,
						item.createdAt,
						item.category.category.categoryLocale
							? item.category.category.categoryLocale[0].title
							: '',
						// item.subscriptionSubscriptionTypeCost,
						null,
						item.partnerName,
						!item.disabled,
						this.getOperatorFullName(item),
						item.streetAddress,
						item.city,
						item.phoneNumber,
						item.operatorPerson && item.operatorPerson.agreedToTerms
							? item.operatorPerson.agreedToTerms.createdAt
							: null,
						item.id,
						item.operatorPerson ? item.operatorPerson.id : null,
						item.operatorPerson.locale
					])
				})
				return formattedData
			},
			editMember(memberId) {
				this.$router.push({ path: `/dashboard/users/members/${memberId}` });
			},
			setFilters(filters) {
				this.filters = filters;
			},
			setMemberStatus(memberId, status) {
				const isDisabled = !!status;
				const statusType = isDisabled ? 'enable' : 'disable';
				const answer = window.confirm(
					`Do you really want to ${statusType} this account?\nMember ID: ${memberId}`
				);

				if (answer) {
					this.attemptUpdateMemberStatus({ memberId, status })
						.then(() => this.getData())
						.catch((e) => this.validationErrors(e));
				}
			},
		},
	};
</script>

<style>
</style>
